'use client';

import { useEffect } from 'react';

// MUI
import { ThemeProvider } from '@mui/material';

// Components
import { GlobalErrorFallback } from 'src/components/GlobalErrorFallback/GlobalErrorFallback';

// Styles
import theme from '@/theme/theme';

export default function GlobalError ( {
    error
    , reset
}: {
  error: Error & { digest?: string };
  reset: () => void;
} ) {

    useEffect( () => {
        console.error( 'Global Error Fallback', error );
    }, [ error ] );

    return (
        <html lang='en'>
            <body>
                <ThemeProvider theme={ theme }>
                    <GlobalErrorFallback
                        error={ error }
                        resetErrorBoundary={ reset }
                    />
                </ThemeProvider>
            </body>
        </html>
    );
}
GlobalError.displayName = 'GlobalError';
